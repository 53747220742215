import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldCurrencyInput } from '../../../components';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from './Offers.module.css';
import IconPromote from '../../../components/IconPromote/IconPromote';
import { formatMoney } from '../../../util/currency';
import appSettings from '../../../config/settings';
import { getPriceValidators } from '../../EditListingPage/EditListingWizard/EditListingPricingAndStockPanel/EditListingPricingAndStockForm';

const { Money } = sdkTypes;

const listingMinimumPriceSubUnits = 0;
const baseCurrency = 'USD';

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        invalid,
        config,
        inquiryInProgress,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );

      const priceValidators = getPriceValidators(listingMinimumPriceSubUnits, baseCurrency, intl);

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconPromote className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="OfferForm.heading" values={{ listingTitle, authorDisplayName }} />
          </h2>
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.input}
            currencyConfig={appSettings.getCurrencyFormatting('USD')}
            validate={priceValidators}
          />
          <div className={css.actionBtns}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="OfferForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={inquiryInProgress}
              disabled={invalid}
            >
              <FormattedMessage id="OfferForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

OfferForm.displayName = 'OfferForm';

export default OfferForm;
