import React from 'react';
import { Modal } from '../../../components';
import { selectInquiryInProgress, sendInquiry } from '../ListingPage.duck';
import OfferForm from './OfferForm';

import css from './Offers.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../ducks/user.duck';
import moment from 'moment';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';

function OfferModal(props) {
  const {
    onManageDisableScrolling,
    onOpenOffersModal,
    isOffersModalOpen,
    config,
    listingTitle,
    authorDisplayName,
    listingId,
    listing,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const routes = routeConfiguration();
  const currentUser = useSelector(selectCurrentUser);
  const inquiryInProgress = useSelector(selectInquiryInProgress);

  const onSendOfferRequest = async values => {
    const { price } = values;
    const offerPrice = Number(price?.amount / 100).toFixed(2);

    const offerParams = {
      offerPrice,
      authorId: currentUser?.id?.uuid,
      createdAt: moment().unix(),
      type: 'initial',
    };

    const messageParams = `I would like to make an offer for $${offerPrice}`;

    try {
      const txId = await dispatch(sendInquiry(listing, messageParams, offerParams));

      // Redirect to OrderDetailsPage
      history.push(createResourceLocatorString('OffersPage', routes, {}, {}));
    } catch (error) {
      // Error handling logic here if needed
      console.error('Failed to send enquiry:', error);
    }
  };

  const offersModal = (
    <Modal
      id="OfferModal"
      isOpen={!!isOffersModalOpen}
      onClose={() => onOpenOffersModal(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <OfferForm
        onSubmit={onSendOfferRequest}
        config={config}
        listingTitle={listingTitle}
        inquiryInProgress={inquiryInProgress}
        authorDisplayName={authorDisplayName}
      />
    </Modal>
  );

  return <div>{offersModal}</div>;
}

export default OfferModal;
