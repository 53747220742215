import React from 'react';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Modal } from '../../../components';

import css from './SocialSharing.module.css';

function SocialSharing(props) {
  const {
    onManageDisableScrolling,
    onOpenShareModal,
    isSocialSharingModalOpen,
    listing,
    listingTitle,
    listingImage,
    description,
    productURL: currentURL,
  } = props;

  const sharingModal = (
    <Modal
      id="ListingPageFullImage"
      isOpen={isSocialSharingModalOpen}
      onClose={() => onOpenShareModal(false)}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      isSocialSharingModal={true}
    >
      <div className={css.shareContainer}>
        <p className={css.shareTitle}>Share {listingTitle}</p>
        <ul className={css.socialMedialinks}>
          <li>
            <FacebookShareButton url={currentURL} quote={listingTitle}>
              <span className={css.leftIcon}>
                <svg
                  width="198"
                  height="199"
                  viewBox="0 0 198 199"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M197.97 99.5763C197.97 149.468 160.997 190.715 112.968 197.408C108.477 198.031 103.882 198.357 99.2181 198.357C93.8346 198.357 88.5479 197.928 83.3997 197.097C36.3732 189.525 0.459839 148.742 0.459839 99.5763C0.459839 45.019 44.6769 0.78894 99.2112 0.78894C153.746 0.78894 197.97 45.019 197.97 99.5763Z"
                    fill="#1877F2"
                  />
                  <path
                    d="M112.968 80.1055V101.625H139.581L135.367 130.614H112.968V197.402C108.477 198.025 103.882 198.35 99.2181 198.35C93.8346 198.35 88.5479 197.921 83.3996 197.09V130.614H58.8553V101.625H83.3996V75.2949C83.3996 58.9595 96.637 45.7112 112.975 45.7112V45.7251C113.023 45.7251 113.064 45.7112 113.113 45.7112H139.588V70.7819H122.288C117.147 70.7819 112.975 74.9557 112.975 80.0986L112.968 80.1055Z"
                    fill="white"
                  />
                </svg>
                Facebook
              </span>
            </FacebookShareButton>
          </li>
          <li>
            <FacebookMessengerShareButton
              url={currentURL}
              quote={listingTitle}
              appId="733319158994790"
            >
              <span className={css.leftIcon}>
                <svg
                  width="199"
                  height="198"
                  viewBox="0 0 199 198"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M99.9653 0.0303955H99.5156C45.099 0.0303955 0.985657 44.1568 0.985657 98.5894V99.0393C0.985657 153.472 45.099 197.598 99.5156 197.598H99.9653C154.382 197.598 198.495 153.472 198.495 99.0393V98.5894C198.495 44.1568 154.382 0.0303955 99.9653 0.0303955Z"
                    fill="url(#paint0_linear_1_384)"
                  />
                  <path
                    d="M158.541 98.8109V99.1501C158.382 127.425 138.162 151.049 111.418 156.462C109.605 156.822 107.771 157.106 105.903 157.307C103.882 157.514 101.82 157.632 99.744 157.632C91.5787 157.632 83.801 155.964 76.729 152.939L49.4445 155.846C44.7875 156.345 41.3485 151.589 43.2791 147.326L50.614 131.08C44.5039 121.812 40.9402 110.716 40.9402 98.8109C40.9402 66.3894 67.3251 39.9967 99.744 39.9967C130.398 39.9967 155.648 63.5861 158.306 93.5712C158.465 95.2878 158.541 97.0528 158.541 98.8109Z"
                    fill="white"
                  />
                  <path
                    d="M128.011 81.6381C130.647 79.4785 134.266 82.711 132.412 85.5696L120.067 104.618L113.535 114.696C112.144 116.842 109.224 117.354 107.183 115.811L96.3533 107.595L89.6758 102.535C89.136 102.127 88.3818 102.14 87.8559 102.569L71.47 115.942C68.8336 118.095 65.2215 114.863 67.076 112.004L78.6388 94.1734L85.171 84.0953C86.5619 81.9496 89.482 81.4373 91.5234 82.9809L102.034 90.9478L108.705 96.0007C109.252 96.4091 110.006 96.3952 110.532 95.9661L128.011 81.6312V81.6381Z"
                    fill="url(#paint1_linear_1_384)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_384"
                      x1="29.8409"
                      y1="168.734"
                      x2="169.674"
                      y2="28.9423"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0498FA" />
                      <stop offset="0.34" stopColor="#5C5EFE" />
                      <stop offset="0.73" stopColor="#F44E90" />
                      <stop offset="1" stopColor="#FA8679" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1_384"
                      x1="75.2344"
                      y1="123.515"
                      x2="124.469"
                      y2="74.2948"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0498FA" />
                      <stop offset="0.34" stopColor="#5C5EFE" />
                      <stop offset="0.73" stopColor="#F44E90" />
                      <stop offset="1" stopColor="#FA8679" />
                    </linearGradient>
                  </defs>
                </svg>
                Messenger
              </span>
            </FacebookMessengerShareButton>
          </li>
          <li>
            <TwitterShareButton title={listingTitle} url={currentURL}>
              <svg
                width="198"
                height="198"
                viewBox="0 0 198 198"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M197.495 99.1577C197.495 149.05 160.523 190.297 112.493 196.99C108.003 197.613 103.408 197.938 98.744 197.938C93.3604 197.938 88.0738 197.509 82.9255 196.678C35.906 189.106 -0.00738525 148.323 -0.00738525 99.1577C-0.00738525 44.6004 44.2096 0.370361 98.7509 0.370361C153.292 0.370361 197.509 44.6004 197.509 99.1577H197.495Z"
                  fill="#1C1C1B"
                />
                <path
                  d="M40.044 43.9359L85.5896 104.847L39.7603 154.373H50.0776L90.2051 111.015L122.624 154.373H157.728L109.622 90.0349L152.282 43.9359H141.965L105.013 83.8676L75.1547 43.9359H40.0509H40.044ZM55.212 51.536H71.335L142.546 146.773H126.423L55.212 51.536Z"
                  fill="white"
                />
              </svg>
              Twitter
            </TwitterShareButton>
          </li>
          <li>
            <WhatsappShareButton title={listingTitle} url={currentURL}>
              <svg
                width="199"
                height="199"
                viewBox="0 0 199 199"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M99.8074 0.78894H99.3576C44.941 0.78894 0.827637 44.9153 0.827637 99.3479V99.7978C0.827637 154.23 44.941 198.357 99.3576 198.357H99.8074C154.224 198.357 198.337 154.23 198.337 99.7978V99.3479C198.337 44.9153 154.224 0.78894 99.8074 0.78894Z"
                  fill="#00E510"
                />
                <path
                  d="M118.83 128.468C93.5519 128.468 72.9865 107.889 72.9796 82.6042C72.9865 76.1947 78.204 70.9826 84.5978 70.9826C85.2552 70.9826 85.9056 71.038 86.5284 71.1487C87.8985 71.3771 89.1994 71.8409 90.3965 72.54C90.5695 72.6438 90.6871 72.8099 90.7148 73.0038L93.3858 89.8444C93.4204 90.0451 93.3582 90.2389 93.2267 90.3843C91.7528 92.0178 89.8706 93.1945 87.7739 93.7829L86.7637 94.0667L87.1442 95.0426C90.5902 103.819 97.6069 110.831 106.388 114.292L107.364 114.68L107.647 113.669C108.236 111.572 109.412 109.689 111.045 108.215C111.163 108.104 111.322 108.049 111.481 108.049C111.516 108.049 111.55 108.049 111.592 108.056L128.427 110.727C128.628 110.762 128.794 110.873 128.898 111.046C129.59 112.243 130.053 113.551 130.289 114.922C130.399 115.531 130.448 116.175 130.448 116.846C130.448 123.249 125.237 128.461 118.83 128.468Z"
                  fill="#FDFDFD"
                />
                <path
                  d="M163.656 94.0251C162.292 78.6173 155.234 64.3238 143.782 53.782C132.261 43.1778 117.314 37.3359 101.683 37.3359C67.3746 37.3359 39.4605 65.2583 39.4605 99.5764C39.4605 111.094 42.6366 122.314 48.6498 132.088L35.2394 161.782L78.1763 157.207C85.6427 160.266 93.545 161.817 101.676 161.817C103.814 161.817 106.007 161.706 108.208 161.478C110.145 161.27 112.104 160.965 114.027 160.578C142.758 154.77 163.732 129.264 163.898 99.9086V99.5764C163.898 97.7075 163.815 95.8386 163.649 94.0251H163.656ZM79.8301 144.173L56.0747 146.707L63.1675 130.987L61.7489 129.084C61.6451 128.945 61.5413 128.807 61.4237 128.648C55.2651 120.141 52.0128 110.091 52.0128 99.5833C52.0128 72.1869 74.2943 49.8989 101.683 49.8989C127.341 49.8989 149.09 69.9235 151.186 95.4856C151.297 96.8561 151.359 98.2335 151.359 99.5902C151.359 99.9778 151.352 100.359 151.345 100.767C150.82 123.685 134.814 143.149 112.422 148.105C110.713 148.486 108.962 148.776 107.218 148.963C105.405 149.171 103.544 149.275 101.696 149.275C95.1158 149.275 88.722 148.001 82.681 145.481C82.0098 145.212 81.3524 144.921 80.7366 144.623L79.8371 144.187L79.8301 144.173Z"
                  fill="#FDFDFD"
                />
              </svg>
              Whatsapp
            </WhatsappShareButton>
          </li>
          <li>
            <PinterestShareButton media={listingImage} description={description} url={currentURL}>
              <svg
                width="198"
                height="198"
                viewBox="0 0 198 198"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M99.1236 0.0303955H98.6738C44.2572 0.0303955 0.143799 44.1568 0.143799 98.5894V99.0393C0.143799 153.472 44.2572 197.598 98.6738 197.598H99.1236C153.54 197.598 197.654 153.472 197.654 99.0393V98.5894C197.654 44.1568 153.54 0.0303955 99.1236 0.0303955Z"
                  fill="#FF0000"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M89.8856 125.826C89.768 126.235 89.6573 126.581 89.5673 126.927C84.3429 147.402 83.7617 151.949 78.392 161.46C75.8317 165.98 72.9461 170.264 69.7562 174.362C69.3963 174.826 69.0572 175.421 68.3376 175.276C67.5487 175.109 67.4865 174.396 67.4034 173.767C66.5454 167.544 66.0679 161.307 66.2755 155.022C66.5454 146.841 67.5557 144.031 78.1013 99.6761C78.2536 98.9978 78.0806 98.4371 77.8591 97.8279C75.3334 91.0169 74.8352 84.1021 77.0426 77.0973C81.8172 61.9663 98.9643 60.8104 101.961 73.2903C103.808 81.0081 98.9228 91.1138 95.1723 106.051C92.0653 118.372 106.569 127.135 118.962 118.136C130.394 109.844 134.829 89.9648 133.985 75.8721C132.324 47.7767 101.525 41.7063 81.9902 50.7531C59.5911 61.1149 54.4982 88.8851 64.6148 101.58C65.895 103.192 68.3099 104.182 67.8878 105.816C67.2374 108.356 66.663 110.917 65.9572 113.443C65.4313 115.326 62.4351 116.011 60.5322 115.236C56.8024 113.727 53.6955 111.353 51.1767 108.224C42.5893 97.5857 40.1328 76.5435 51.4881 58.7269C64.0681 38.993 87.4706 31.0053 108.839 33.4279C134.359 36.3281 150.489 53.771 153.506 73.5603C154.883 82.5724 153.893 104.798 141.244 120.511C126.692 138.563 103.102 139.76 92.2175 128.678C91.3803 127.827 90.7091 126.837 89.8856 125.82V125.826Z"
                  fill="white"
                />
              </svg>
              Pinterest
            </PinterestShareButton>
          </li>
          <li>
            <RedditShareButton title={listingTitle} url={currentURL}>
              <svg
                width="199"
                height="198"
                viewBox="0 0 199 198"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M99.1995 0.0303955H99.6492C154.031 0.0303955 198.179 44.1912 198.179 98.5894V99.0393C198.179 153.437 154.031 197.598 99.6492 197.598H99.1995C44.8174 197.598 0.669556 153.437 0.669556 99.0393V98.5894C0.669556 44.1912 44.8174 0.0303955 99.1995 0.0303955Z"
                  fill="#FF4500"
                />
                <path
                  d="M160.612 96.3399C158.972 88.899 152.177 84.0745 145.437 85.5627C143.14 86.068 141.161 87.2516 139.597 88.8713C130.525 82.2818 118.139 77.928 104.306 77.1043L110.928 49.528L130.573 54.2279C130.899 59.5992 135.348 63.8561 140.801 63.8561C146.254 63.8561 151.056 59.26 151.056 53.598C151.056 47.936 146.461 43.34 140.801 43.34C136.697 43.34 133.162 45.7557 131.528 49.2442L110.216 44.1429C108.541 43.7414 106.86 44.7728 106.451 46.4478L99.1234 76.9659C83.6924 77.0489 69.7699 81.5135 59.7778 88.6636C59.2865 88.2068 58.7607 87.7846 58.1863 87.4108C52.3669 83.6938 44.3261 85.888 40.2296 92.3045C36.1331 98.721 37.524 106.944 43.3435 110.661C43.6895 110.883 44.0424 111.076 44.4022 111.256C44.167 112.689 44.0424 114.15 44.0424 115.624C44.0424 136.978 68.8912 154.289 99.5455 154.289C130.2 154.289 155.049 136.978 155.049 115.624C155.049 114.129 154.917 112.654 154.675 111.208C159.401 108.46 161.975 102.473 160.626 96.3399H160.612ZM71.0847 106.667C71.0847 101.801 75.0289 97.8558 79.8935 97.8558C84.7581 97.8558 88.7024 101.801 88.7024 106.667C88.7024 111.533 84.7581 115.479 79.8935 115.479C75.0289 115.479 71.0847 111.533 71.0847 106.667ZM120.007 134.486C117.302 136.943 109.828 142.674 98.8882 142.778C98.7913 142.778 98.6874 142.778 98.5905 142.778C87.5051 142.778 79.935 136.991 77.174 134.486C76.2883 133.683 76.226 132.312 77.0287 131.433C77.8314 130.547 79.2016 130.485 80.0804 131.288C82.4815 133.475 89.1175 138.549 98.8466 138.459C108.306 138.369 114.762 133.42 117.101 131.295C117.987 130.492 119.35 130.554 120.153 131.44C120.955 132.326 120.893 133.69 120.007 134.493V134.486ZM118.942 115.479C114.077 115.479 110.133 111.533 110.133 106.667C110.133 101.801 114.077 97.8558 118.942 97.8558C123.806 97.8558 127.75 101.801 127.75 106.667C127.75 111.533 123.806 115.479 118.942 115.479Z"
                  fill="white"
                />
              </svg>
              Reddit
            </RedditShareButton>
          </li>
          <li>
            <EmailShareButton
              url={currentURL}
              subject={listingTitle}
              body="Please checkout this listing"
            >
              <svg
                width="18"
                height="19"
                fill="#000000"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M22,5V9L12,13,2,9V5A1,1,0,0,1,3,4H21A1,1,0,0,1,22,5ZM2,11.154V19a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V11.154l-10,4Z"></path>
                </g>
              </svg>
              Email
            </EmailShareButton>
          </li>
        </ul>
      </div>
    </Modal>
  );

  return <div>{sharingModal}</div>;
}

export default SocialSharing;
